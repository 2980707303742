import * as React from 'react'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
import * as styles from '../../styles/workshop.module.css'

const RoseWindow = () => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      isHome={false}
      title="「ローズウィンドウ」ワークショップ"
      description="Mondでは、ヨーロッパの大聖堂にある丸いステンドグラス「バラ窓」を模したペーパークラフト「ローズウィンドウ」のワークショップを毎月開催しています。"
      image="https://www.mond.nagoya/static/48159c7b5ef7ebc3ed3501863d0d414f/d924c/thumbnail-rosewindow.jpg"
      article={true}
    >
      <div className={styles.workshopContainer}>
        <h1 className={styles.workshopTitle}>Workshop</h1>
        <div className={styles.workshopGrid}>
          <div className={styles.workshopBody}>
            {breakpoints.xs || breakpoints.sm || breakpoints.md
              ? <hr className={styles.workshopHr}/> : null
            }
            <StaticImage
              className={styles.workshopImage}
              alt="Workshop - ローズウィンドウ"
              src="../../assets/images/thumbnail-rosewindow.jpg"
            />
            <h1 className={styles.workshopName}>ローズウィンドウ</h1>
            <hr className={styles.workshopHr}/>
            <p className={styles.workshopText}>
              ヨーロッパの大聖堂にある、丸いステンドグラス「<strong>バラ窓</strong>」を模したペーパークラフト「<strong>ローズウィンドウ</strong>」のワークショップを毎月開催しています。
            </p>
            <h2 className={styles.workshopH2}>「ローズウィンドウ」とは？</h2>
            <p className={styles.workshopText}>
              もともとは<strong>ドイツ</strong>発祥のペーパークラフトで、ドイツやヨーロッパにある大聖堂や教会のステンドグラスでできた丸い窓「<strong>バラ窓＝ローズウインドウ</strong>」からインスパイアされています。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ローズウィンドウ」とは？"
                src="../../assets/images/rose-1.jpg"
              />
            </div>
            <h2 className={styles.workshopH2}>ローズウィンドウの作り方</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-2.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              ３4色のローズウィンドウ専用ペーパーの中から、お好みのカラーを選びます。<br/>
              図案にもよりますが、ワークショップでは3枚選んでいただくことが多いです。<br/>
              <br/>
              白を3枚選んでも、3枚が重なり合うので奥行きが生まれます。<br/>
              <br/>
              またカラーセラピーのようにその時の気分によっても選ぶ色が異なるのが面白いです。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-3.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              図案に沿って、ローズウィンドウペーパーを一枚ずつカットしていきます。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-4.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              図案は初めての方はカットが大きく数が少なく、慣れてくるとレベルアップして難しい図案に挑戦することができます。<br/>
              <br/>
              これは中級〜上級者さんでカットも細かいですね。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-5.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              破れてしまわないようにそっと広げます。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-6.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              異なる模様をカットしたペーパーを3枚重ねて
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-7.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              枠をはめて完成です！<br/>
              こちらは数回体験された初級の方の作品です。<br/>
              <br/>
              このようにライトに透かして見ると、ステンドグラスのように綺麗ですね。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ローズウィンドウの作り方"
                src="../../assets/images/rose-8.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              こちらは細かい柄の上級者さんの作品です。<br/>
              パステルカラーの組み合わせも柔らかな光が浮き上がって素敵ですね。
            </p>
            <h2 className={styles.workshopH2}>色の三原色</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="色の三原色"
                src="../..//assets/images/rose-9.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              こちらは色の三原色の性質を活かしたローズウィンドウです。<br/>
              色の三原色とは青緑（C：シアン）、赤紫（M：マゼンタ）、黄（Y：イエロー）の三色のことです。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="色の三原色"
                src="../../assets/images/thumbnail-rosewindow.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              こちらは上のローズウインドウを光に当てたものです。<br/>
              色の三原色の三色が重なり合うことで、オレンジ、緑、紫、そして黒が浮かび上がってきましたね。<br/>
              <br/>
              初めての方はこの三原色で作ると、室内光と光を当てた時の差がわかりやすくて面白いかもしれません。<br/>
              私はこの現象がなんとも不思議で、何度も比べて見てしまいます。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="色の三原色"
                src="../../assets/images/rose-10.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              作っている段階では分からないので、どんな色になるかなと考えながら、制作後に光に当てるのも楽しみの一つです。
            </p>
            <h2 className={styles.workshopInstructor}>ワークショップ講師：大山幸美さん</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="ワークショップ講師：大山幸美さん"
                src="../../assets/images/oyama-yukimi.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              ローズウィンドウ講師歴：6年<br/>
              <br/>
              HSPという気質があり、感受性が強く繊細で生きづらい毎日を送っていたある時、ローズウィンドウと出逢い、その美しさに目を奪われて興味を惹かれる。<br/>
              そしてローズウィンドウは自己表現の一つとして、生活に癒しを与えてくれる存在となる。<br/>
              <br/>
              ものづくりを通して考えることを傍に置き、今この瞬間を大切にして、いろんな感覚を共有できたらという想いでワークショップを開催。
            </p>
            <h2 className={styles.workshopH2}>「ローズウィンドウ」ワークショップ</h2>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ローズウィンドウ」ワークショップ"
                src="../../assets/images/rose-11.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              ワークショップは初めての方からご参加いただけます。<br/>
              <br/>
              "シュタイナー教育"にも取り入れられており、モーントのワークショップでも6歳から、親御さん同伴でご参加いただけます。<br/>
              <br/>
              お子さんからご年配の方、男女問わず幅広く楽しんでいただいています。
            </p>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ローズウィンドウ」ワークショップ"
                src="../../assets/images/rose-12.jpg"
              />
            </div>
            <div className={styles.workshopImageWrapper}>
              <StaticImage
                className={styles.workshopImage}
                alt="「ローズウィンドウ」ワークショップ"
                src="../../assets/images/rose-13.jpg"
              />
            </div>
            <p className={styles.workshopText}>
              初めての方でも皆さんちゃんと完成していますので、ぜひお気軽にご参加くださいませ。
            </p>
            <hr className={styles.workshopHr}/>
            <h2 className={styles.workshopH2}>「ローズウィンドウ」ワークショップ詳細</h2>
            <p className={styles.workshopText}>
              ・毎月開催<br/>
              ・所要時間2時間<br/>
              ・料金：3000円（税込）+ ワンドリンク<br/>
              {/* ・日時：<Link to="/#schedule"><strong>スケジュール</strong></Link> */}
              ・日時：LINE・メール・電話でお問い合わせください
            </p>
            <h2 className={styles.workshopH2}>ご予約・お問い合わせ</h2>
            <p className={styles.workshopText}>
              Mond店頭・メール・LINE・電話などで受け付けています。<br/>
              【電話】052-750-5188<br/>
              【メールアドレス】mond14@icloud.com<br/>
              <br/>
              <a className={styles.workshopLineButton} href="https://lin.ee/oekwnCy" target="_blank" rel="noopener noreferrer">
                LINEでかんたん予約
              </a>
            </p>
            <h2 className={styles.workshopH2}>Mond（モーント）</h2>
            <p className={styles.workshopText}>
              名古屋市中区千代田3-6-19<br/>
              地下鉄・JR「鶴舞」駅から徒歩約5分<br/>
              <br/>
              【営業時間】 10〜18時（ラストオーダー17:30）<br/>
              【定休日】 毎週水曜日、第1・第3木曜日
            </p>
          </div>
          <div className={styles.otherWorkshopsContainer}>
            <h1 className={styles.otherWorkshopsTitle}>
              Other Workshop<span className={styles.otherWorkshopsTitleJp}>その他のワークショップ</span>
            </h1>
            <hr className={styles.otherWorkshopsHr}/>
            <div className={styles.otherWorkshopsCardWrapper}>
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <Link to="/workshop/mbira">
                    <StaticImage
                      className={styles.otherWorkshopsCardImage}
                      alt="ジンバブエ楽器「ンビラ」"
                      src="../../assets/images/thumbnail-mbira.jpg"
                    />
                  </Link>
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>ジンバブエ楽器「ンビラ」</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    鉄の棒と木の板から作られたジンバブエの伝統楽器「ンビラ」の弾き方を学ぶワークショップを毎月開催しています。
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <StaticImage
                    className={styles.otherWorkshopsCardImage}
                    alt="ステンドグラス"
                    src="../../assets/images/thumbnail-stained-glass.jpg"
                  />
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>ステンドグラス</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    準備中…
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
              <div className={styles.otherWorkshopsCard}>
                <div className={styles.otherWorkshopsCardImageWrapper}>
                  <StaticImage
                    className={styles.otherWorkshopsCardImage}
                    alt="真鍮アクセサリー"
                    src="../../assets/images/thumbnail-brass.jpg"
                  />
                </div>
                <div className={styles.otherWorkshopsCardBody}>
                  <h2 className={styles.otherWorkshopsCardTitle}>真鍮アクセサリー</h2>
                  <p className={styles.otherWorkshopsCardText}>
                    準備中…
                  </p>
                </div>
              </div>
              {breakpoints.xs || breakpoints.sm || breakpoints.md
                ? null : <hr className={styles.otherWorkshopsCardHr}/>
              }
            </div>
            {breakpoints.xs || breakpoints.sm || breakpoints.md
              ? <hr className={styles.workshopHr}/> : null
            }
          </div>
        </div>
        {breakpoints.xs || breakpoints.sm || breakpoints.md
          ? null : <hr className={styles.workshopBottomHr}/>
        }
      </div>
    </Layout>
  )
}

export default RoseWindow
