// extracted by mini-css-extract-plugin
export var otherWorkshopsCard = "workshop-module--other-workshops-card--09c84";
export var otherWorkshopsCardBody = "workshop-module--other-workshops-card-body--95f22";
export var otherWorkshopsCardHr = "workshop-module--other-workshops-card-hr--e45ca";
export var otherWorkshopsCardImage = "workshop-module--other-workshops-card-image--2d1cf";
export var otherWorkshopsCardImageWrapper = "workshop-module--other-workshops-card-image-wrapper--b0ea1";
export var otherWorkshopsCardText = "workshop-module--other-workshops-card-text--1b9e2";
export var otherWorkshopsCardTitle = "workshop-module--other-workshops-card-title--ef892";
export var otherWorkshopsCardWrapper = "workshop-module--other-workshops-card-wrapper--4d1a2";
export var otherWorkshopsContainer = "workshop-module--other-workshops-container--e8a1e";
export var otherWorkshopsHr = "workshop-module--other-workshops-hr--6cdad";
export var otherWorkshopsTitle = "workshop-module--other-workshops-title--1f988";
export var otherWorkshopsTitleJp = "workshop-module--other-workshops-title-jp--22e03";
export var workshopBody = "workshop-module--workshop-body--1e5cb";
export var workshopBottomHr = "workshop-module--workshop-bottom-hr--3c2b4";
export var workshopContainer = "workshop-module--workshop-container--8de53";
export var workshopGrid = "workshop-module--workshop-grid--d24a6";
export var workshopH2 = "workshop-module--workshop-h2--d6bc3";
export var workshopHr = "workshop-module--workshop-hr--c03a2";
export var workshopImage = "workshop-module--workshop-image--679cc";
export var workshopImageWrapper = "workshop-module--workshop-image-wrapper--f0d40";
export var workshopInstructor = "workshop-module--workshop-instructor--bdebe";
export var workshopLineButton = "workshop-module--workshop-line-button--ace9d";
export var workshopName = "workshop-module--workshop-name--384d1";
export var workshopText = "workshop-module--workshop-text--03f30";
export var workshopTitle = "workshop-module--workshop-title--282fa";